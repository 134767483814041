<template lang="pug">
.brand-style-guide.container-fluid
  .row
    .d-flex.col-2.px-0
      side-sub-nav(:menuItems="menuItems" :onlyEmit="true" @click="onNavClick")
    .col-10.py-5.px-3
      header.px-4
        h1.font-weight-bold {{ $t('styleGuide') }}
      .main.px-4
        section.my-5
          transition(name="fade")
            component(:is="active")
</template>

<script>
  import SideSubNav from '@/components/SideSubNav.vue';
  import * as StyleGuideParts from './StyleGuide/index';

  const PARTS = Object.keys(StyleGuideParts);

  export default {
    components: {
      SideSubNav,
      ...StyleGuideParts,
    },

    data() {
      return {
        active: PARTS[0],
        nameOverrides: {
          UniqueIcons: 'Unique Icons',
          BodyTexts: 'Body Texts',
          BodyTextLinks: 'Body Text Links',
          AllCapsTexts: 'All Caps Texts',
        },
      };
    },

    computed: {
      styleGuideParts() {
        return Object.keys(StyleGuideParts);
      },
      menuItems() {
        return this.styleGuideParts.map((name) => ({
          link: `#${name}`,
          name: this.nameOverrides[name] ? this.nameOverrides[name] : name,
        }));
      },
    },
    methods: {
      onNavClick(name) {
        const realName = name.replaceAll(' ', '');
        if (this.active !== realName && PARTS.includes(realName)) this.active = realName;
      },
    },
  };
</script>

<style lang="sass">
  figure.highlighted
    padding: 1rem
    margin-top: 1rem
    margin-bottom: 1rem
    background-color: #F6F7F9
  .brand-container
    &-try
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      border: 1px solid #e3e5e8
      padding: 2rem
    &-choose
      background: white
      padding: 1rem
      border-radius: 5px
      text-transform: capitalize
      label:last-letter
        text-transform: none
</style>
